import React from "react";
import {
	AppBar,
	Button,
	Checkbox,
	Dialog,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Slide,
	TextField,
	Toolbar,
	Typography,
	makeStyles,
	Theme,
	createStyles,
	Collapse,
} from "@material-ui/core";

// Icons
import { Close, Delete } from "@material-ui/icons";
// Props
import { TransitionProps } from "@material-ui/core/transitions";
// Hooks
import { useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

import { IRoleUser } from "../../../interfaces";

interface Props {
	open: boolean;
	user: any;
	handleClose: () => void;
	handleDeleteUser: (user: IRoleUser) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			position: "relative",
		},
		title: {
			color: theme.palette.getContrastText(theme.palette.primary.main),
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		nested: {
			paddingLeft: theme.spacing(4),
		},
		buttonContainer: {
			display: "flex",
			justifyContent: "space-between",
			padding: `0 ${theme.spacing(2)}px`,
		},
	})
);

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ManageUserDialog({
	open,
	user,
	handleClose,
	handleDeleteUser,
}: Props) {
	const classes = useStyles();
	const params: any = useParams();
	const uid = useSelector((state: any) => state.firebase.auth?.uid);

	const groupId = params?.groupId;
	const roleId = params?.roleId;

	const userPath = `users/${user?.uid}`;
	const roleUserPath = `users/${uid}/manage/groups/${groupId}/roles/${roleId}/users/${user?.userId}`;

	const [warn, setWarn] = useState(false);

	console.log(userPath);
	console.log(roleUserPath);

	if (!user) return null;

	const {
		firstName = "",
		lastName = "",
		email = "",
		phone = "",
		access = {
			canAddUsers: false,
			canManageGroup: false,
			disabled: false,
		},
	} = user;
	console.log(user);

	// Handlers
	const handleCanAddUsers = () => {};
	const handleCanManageGroup = () => {};
	const handleDisableUser = () => {};

	return (
		<div>
			<Dialog
				TransitionComponent={Transition}
				fullScreen
				open={open}
				onClose={handleClose}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<Close />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{firstName} {lastName} {Boolean(phone) && phone}
						</Typography>
					</Toolbar>
				</AppBar>

				<List style={{ padding: "1rem" }}>
					<ListItem>
						<TextField
							autoFocus
							margin="dense"
							id="name"
							label="Email Address"
							type="email"
							fullWidth
							value={email}
							disabled={true}
							helperText={"User email cannot be changed at this time."}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Access"
							secondary="Define the scope of your user's privilages within the group"
						/>
					</ListItem>
					<Divider />
					<ListItem className={classes.nested}>
						<ListItemText
							primary="Can Add Users"
							secondary="This user can assign users to the group"
						/>
						<ListItemSecondaryAction>
							<Checkbox
								edge="end"
								onChange={() => handleCanAddUsers()}
								checked={Boolean(access.canAddUsers)}
								inputProps={{
									"aria-labelledby": "enable-whitelabel",
								}}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem className={classes.nested}>
						<ListItemText
							primary="Can Manage Group"
							secondary="This user can adjust settings within this group"
						/>
						<ListItemSecondaryAction>
							<Checkbox
								edge="end"
								onChange={() => handleCanManageGroup()}
								checked={Boolean(access.canManageGroup)}
								inputProps={{
									"aria-labelledby": "enable-whitelabel",
								}}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem className={classes.nested}>
						<ListItemText
							primary="Disable User"
							secondary="Prevent this user from authenticating into all applications"
						/>
						<ListItemSecondaryAction>
							<Checkbox
								edge="end"
								onChange={() => handleDisableUser()}
								checked={Boolean(access.disabled)}
								inputProps={{
									"aria-labelledby": "enable-whitelabel",
								}}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					<Divider />
					<ListItem
						className={classes.nested}
						button
						onClick={() => setWarn(!warn)}
					>
						<ListItemText
							primary="Delete User"
							secondary="Delete user and all associated data. Warning, this is permanent."
						/>
						<ListItemSecondaryAction style={{ zIndex: -1 }}>
							<Delete />
						</ListItemSecondaryAction>
					</ListItem>
					<Collapse in={warn}>
						<div className={classes.buttonContainer}>
							<Button onClick={() => setWarn(false)}>Cancel</Button>
							<Button onClick={() => handleDeleteUser(user)}>
								Confirm
							</Button>{" "}
						</div>
					</Collapse>
				</List>
				<Divider />
			</Dialog>
		</div>
	);
}

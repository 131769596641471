const INITIAL_STATE: any = { devices: {}, deviceHistories: {} };

function reportReducer(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		//Generic Updates
		case "ADD_DEVICE": {
			return {
				...state,
				devices: { ...state.devices, [`${action.payload.id}`]: action.payload },
			};
		}

		case "ADD_HISTORY": {
			return {
				...state,
				deviceHistories: {
					...state.deviceHistories,
					[`${action.payload.deviceId}`]: {
						...state.deviceHistories[`${action.payload.deviceId}`],
						[`${action.payload.pointId}`]: action.payload,
					},
				},
			};
		}

		case "REMOVE_DEVICE": {
			return { ...state, ...action.payload };
		}

		//Specific Updates

		default:
			return state;
	}
}

// Actions
export const addDevice = (device: any) => ({
	type: "ADD_DEVICE",
	payload: device,
});

export const removeDevice = (device: any) => ({
	type: "REMOVE_DEVICE",
	payload: device,
});

export default reportReducer;

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import reportReducer from "./reportReducer";

const createRootReducer = (history: any) => {
	const reducerBase = combineReducers({
		firebase: firebaseReducer,
		firestore: firestoreReducer,
		report: reportReducer,
		router: connectRouter(history),
	});

	const rootReducer = (state: any, action: { type: string }) => {
		// if (action.type === "RESET_APP") {
		//   state = undefined;
		// }

		return reducerBase(state, action);
	};

	return rootReducer;
};

export default createRootReducer;

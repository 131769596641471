import * as React from "react";

import {
	Checkbox,
	createStyles,
	FormControl,
	FormControlLabel,
	ListItem,
	ListItemText,
	makeStyles,
	RadioGroup,
	Theme,
} from "@material-ui/core";

import clsx from "clsx";
import { lightBlue } from "@material-ui/core/colors";

interface IRoleDeviceProps {
	device: any;
	setParentPermissions: any;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		center: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		column: {
			width: "100%",
			display: "flex",
			flexBasis: "50%",
		},
		columnThird: {
			display: "flex",
			flexBasis: "33%",
		},
		columnTwoThirds: {
			display: "flex",
			flexBasis: "66%",
		},
		end: {
			justifyContent: "flex-end",
		},
		hoverBackground: {
			"&:hover": {
				background: lightBlue[50],
			},
		},
	})
);

const getCheckedState = (data: any, variant: string) => {
	return Boolean(
		data.points &&
			data.points.filter((f: any) => f.permission === variant).length ===
				data.points.length
	);
};

const getIndeterminateState = (data: any, variant: string) => {
	let dataChildren = data.points?.filter((f: any) => f.permission === variant);
	return Boolean(
		dataChildren?.length && dataChildren.length !== data.points?.length
	);
};

export const RoleDevice: React.FC<IRoleDeviceProps> = ({
	device,
	setParentPermissions,
}) => {
	const classes = useStyles();

	console.log(device);

	return (
		<ListItem
			dense
			style={{ padding: "0 1rem 0 5rem" }}
			className={classes.hoverBackground}
		>
			<div className={classes.column}>
				<ListItemText
					primary={device.name}
					secondary={
						device.points?.length
							? `${device.points.length} Points`
							: "No Points Listed"
					}
				/>
			</div>
			<div className={clsx(classes.column, classes.end)}>
				<FormControl
					component="fieldset"
					margin="dense"
					style={{ width: "100%" }}
				>
					<RadioGroup
						row
						aria-label="Assign to all devices in Floor"
						name="mass-select"
						onClick={(event: any) => {
							event.stopPropagation();
						}}
						onFocus={(event: any) => event.stopPropagation()}
					>
						<div
							className={clsx(classes.columnThird, classes.center)}
							style={{ borderRight: "1px solid #ccc" }}
						>
							<FormControlLabel
								onClick={() =>
									setParentPermissions(
										device,
										device.permission === "read" ? "" : "read"
									)
								}
								checked={getCheckedState(device, "read")}
								value="read"
								control={
									<Checkbox
										color="primary"
										size="small"
										indeterminate={getIndeterminateState(device, "read")}
									/>
								}
								label="Read"
								labelPlacement="bottom"
							/>
						</div>
						<div
							className={clsx(classes.columnThird, classes.center)}
							style={{ borderRight: "1px solid #ccc" }}
						>
							<FormControlLabel
								onClick={() =>
									setParentPermissions(
										device,
										device.permission === "write" ? "" : "write"
									)
								}
								checked={getCheckedState(device, "write")}
								value="write"
								control={
									<Checkbox
										color="primary"
										size="small"
										indeterminate={getIndeterminateState(device, "write")}
									/>
								}
								label="Write"
								labelPlacement="bottom"
							/>
						</div>
						<div className={clsx(classes.columnThird, classes.center)}>
							<FormControlLabel
								onClick={() =>
									setParentPermissions(
										device,
										device.permission === "readwrite" ? "" : "readwrite"
									)
								}
								checked={getCheckedState(device, "readwrite")}
								value="readwrite"
								control={
									<Checkbox
										color="primary"
										size="small"
										indeterminate={getIndeterminateState(device, "readwrite")}
									/>
								}
								label="ReadWrite"
								labelPlacement="bottom"
							/>
						</div>
					</RadioGroup>
				</FormControl>
			</div>
		</ListItem>
	);
};
